<template>
  <div>
    <div class="col pointer fade-zoom" @click="SelectCategoy(item.IdCategory)">
      <div class="card">
        <img :src="item.Image" :alt="item.imgAlt" class="category img-thumbnail" />
        <div class="card-footer bg-white text-center">
          <small class="text-muted">{{ item.Name }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//#region Imports
import * as fnsCategories from "@/assets/js/categories.js";
import { inject } from 'vue';
//#endregion

export default {
  name: "CategoryComp",
  props: {
    item: {},
  },
  setup() {
    //#region Usings
    //#endregion

    //#region Injects
    const idCategory = inject('idCategory', 0)
    //#endregion

    //#region Consts
    //#endregion

    //#region Private Methods
    //#endregion

    //#region Public Methods
    const SelectCategoy = (index) => {
      fnsCategories.SetActiveClass(index);
      idCategory.value = index;
    };
    //#endregion

    //#region Providers
    //#endregion

    //#region event
    //#endregion

    return { SelectCategoy };
  },
};
</script>

<style scoped src="@/assets/css/category.css" />
