import { createStore } from 'vuex'

import productsAndServices from '@/store/modules/ProductsAndServices'
import settingsSite from '@/store/modules/SettingsSite'

export default createStore({
  modules : {
    productsAndServices,
    settingsSite
  }
})