<template>
<div class="d-none d-lg-block">
  <div class="nav-header disabled">
    <a class="list-group-item pointer active" @click="SelectCategoy(0)" :name="0">
      <span class="fw-bold">Categories</span>
    </a>
  </div>
  <ul class="list-group" v-for="(category, index) in categories.value" :key="index">
    <a
      class="list-group-item pointer"
      @click="SelectCategoy(category.IdCategory)"
      :name="category.IdCategory"
    >
      {{ category.Name }}
    </a>
  </ul>
</div>
<div class="d-block d-lg-none">
  <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li v-for="(category, index) in categories.value" :key="index" class="breadcrumb-item">
              <a class="breadcrumb-elements pointer" @click="SelectCategoy(category.IdCategory)" :name="category.IdCategory"> {{ category.Name }}</a>
            </li>
          </ol>
  </nav>
</div>
</template>

<script>
//#region Imports
import * as fnsCategories from "@/assets/js/categories.js";
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from "vue";
//#endregion

export default {
  name: "ListCategoriesComp",
  setup() {
    //#region Usings
    const store = useStore();
    //#endregion

    //#region Injects
    const idCategory = inject('idCategory',0)
    const searchText = inject("searchText","");
    const idItem = inject("idItem",0);
    //#endregion

    //#region Consts
    const categories = ref([]);
    //#endregion

    //#region Private Methods
    const loadCategories = () =>{
      categories.value = computed(() => store.getters['productsAndServices/GetCategories'])
    }
    //#endregion

    //#region Public Methods
    const SelectCategoy = (index) => {
      fnsCategories.SetActiveClass(index);
      idCategory.value = index;
      searchText.value = "";
      idItem.value = 0;
    };
    //#endregion

    //#region Providers
    //#endregion

    //#region event
    onMounted(() => {
      loadCategories();
    });
    //#endregion

    return { categories, SelectCategoy };
  },
};
</script>

<style scoped src="@/assets/css/listCategories.css" />