<script>
export default class ContactModel {
  Titles = [];
  List = [];
  Child = [];
  ListUbicacion = [];
  constructor() {
    /*
this.List = [
      {
        Name: "Banco Nacional De Costa Rica",
        Description1: "IBAN: 06015104120010220635",
        Description2: "Corriente: 200-01-041-022063-6",
      },
      {
        Name: "Bac San José",
        Description1: "IBAN: 92010200009259350176",
        Description2: "Corriente: 925935017",
      },
      {
        Name: "Bac San José",
        Description1: "Número: 8823-5466",
        Description2: "",
      },
      {
        Name: "Ticaflor S.A.",
        Description1: "Cedula Juridica 3-101-426145",
        Description2: "",
      },
    ];

    this.ListUbicacion = [
      {
        Name: "Nuestro horario de atención es:",
        Description1: "Lunes a Viernes de 6:30 am a 5:00 pm",
        Description2: "Sabados de 6:30 am a 2:00 pm",
      },
      {
        Name: "Teléfonos",
        Description1: "2530-0258 | 2530-1600",
        Description2: "",
      },
    ];

    this.Child = [
      {
        Name: "Cartago",
        Id: 4,
        Target: "TID4",
      },
      {
        Name: "Pérez Zeledón",
        Id: 5,
        Target: "TID5",
      },
      {
        Name: "Libería",
        Id: 6,
        Target: "TID6",
      },
      {
        Name: "Pinares",
        Id: 7,
        Target: "TID7",
      },
    ];

    this.Titles = [
      {
        Name: "Servicios",
        Id: 1,
        Target: "TID1",
        Img: "concierge-bell",
        Title: "Decoración Floral",
        List: [],
        Child: [],
        Selected: true,
        Description:
          "También le ofrecemos el servicio de decoración floral para sus actividades especiales, bodas, cumpleaños, seminarios y demás. Arreglos florales para cualquier ocasión, entrega a domicilio…",
        DescriptionImg: "http://www.ticaflor.com/img/decorajis.png",
        Visible: true,
      },
      {
        Name: "Cuentas Bancarias",
        Id: 2,
        Target: "TID2",
        Img: "money-check",
        Title: "Numeros de Cuenta",
        Description: "",
        Child: [],
        List: this.List,
        Selected: false,
        DescriptionImg: "",
        Visible: true,
      },
      {
        Name: "Ubicaciones",
        Id: 3,
        Target: "TID3",
        Img: "search-location",
        Title: "",
        List: [],
        Description: "",
        Child: this.Child,
        Selected: false,
        DescriptionImg: "",
        Visible: true,
      },
      {
        Name: "Cartago",
        Id: 4,
        Target: "TID4",
        Img: "concierge-bell",
        Title: "Cartago",
        List: this.ListUbicacion,
        Child: [],
        Selected: false,
        Description:
          "Estamos ubicados en Llano Grande de Cartago. Sobre Carretera principal.",
        DescriptionImg: "http://www.ticaflor.com/img/instalacion_cartago.png",
        Visible: false,
      },
      {
        Name: "Pérez Zeledón",
        Id: 5,
        Target: "TID5",
        Img: "concierge-bell",
        Title: "Pérez Zeledón",
        List: this.ListUbicacion,
        Child: [],
        Selected: false,
        Description:
          "Dirección: Santa Cecilia de la entrada por la pista,de Cerámicas del Valle 300 mts Oeste.",
        DescriptionImg: "http://www.ticaflor.com/img/instalacion_perez2.png",
        Visible: false,
      },
      {
        Name: "Libería",
        Id: 6,
        Target: "TID6",
        Img: "concierge-bell",
        Title: "Libería",
        List: this.ListUbicacion,
        Child: [],
        Selected: false,
        Description: "Dirección: de Pizza Hut 100 mts sur frente iglesia Adventista.",
        DescriptionImg: "http://www.ticaflor.com/img/instalacion_liberia.jpg",
        Visible: false,
      },
      {
        Name: "Pinares",
        Id: 7,
        Target: "TID7",
        Img: "concierge-bell",
        Title: "Pinares",
        List: this.ListUbicacion,
        Child: [],
        Selected: false,
        Description: "Dirección: de Pizza Hut 100 mts sur frente iglesia Adventista.",
        DescriptionImg: "http://www.ticaflor.com/img/Pinares2.jpg",
        Visible: false,
      },
    ];*/
  }
}
</script>
