<template>
  <div class="m-sm-5 m-2">
    <div class="container-fluid" >
      <img :src="usInformation.Us.image" class="w-100" style="max-height: 250px">
    </div>
  </div>

  <div class="m-sm-5 m-2">
    <div class="jumbotron container-fluid">
      <h1 class="display-4">{{usInformation.Us.title}}</h1>
      <p class="lead">
        {{usInformation.Us.description}}</p>
      <hr class="my-4">
    </div>
  </div>

<div class="m-sm-5 m-2">
  <div class="container-fluid">
      <div class="row d-flex justify-content-center">
          <div class="col-xl-4 col-lg-12 mb-3">
            <CompanyInformation :cardInfo="usInformation.Mission"></CompanyInformation>
          </div>
          <div class="col-xl-4 col-lg-6 mb-3">
            <CompanyInformation :cardInfo="usInformation.Vision"></CompanyInformation>
          </div>
          <div class="col-xl-4 col-lg-6 mb-3">
            <CompanyInformation :cardInfo="usInformation.Values"></CompanyInformation>
          </div>
      </div>
  </div>
</div>
</template>

<script>
//#region Imports
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import CompanyInformation from "@/components/CompanyInformation.vue";
//#endregion

export default {
  name: "UsComp",
  components: { CompanyInformation },
  setup() {
     //#region Usings
     const store = useStore();
    //#endregion

    //#region Injects
    //#endregion

    //#region Consts
     const usInformation = ref({
       Mission : {},
       Vision : {},
       Values : {},
       Us : {}
     });
    //#endregion

    //#region Private Methods
    const loadUsInformation = () =>{
      store.dispatch("settingsSite/fechUsInformation")
        .then((response) => {
          usInformation.value = response;
        });
    }
    //#endregion
   
    //#region Public Methods
    //#endregion

    //#region Providers
    //#endregion

    //#region events
    onMounted(() => {
      loadUsInformation();
    });
    //#endregion

    return { usInformation };
  },
};
</script>
