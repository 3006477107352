<template>
      <div class="card p-3 h-100 fade-zoom" >
        <div class="d-flex justify-content-center">
          <img :src="cardInfo.image" class="img-thumbnail" />
        </div>
        <h5 class="text-center pt-sm-4 fw-bold">{{ cardInfo.title }}</h5>
        <p class="lead pt-4 fs-6 text-center">
          {{ cardInfo.description }}
        </p>
      </div>
</template>

<script>
//#region Imports
//#endregion

export default {
  name: "CompanyInformation",
  props: {
    cardInfo: {},
  },
  setup() {
    //#region Usings
    //#endregion

    //#region Injects
    //#endregion

    //#region Consts
    //#endregion

    //#region Private Methods
    //#endregion

    //#region Public Methods
    //#endregion
    
    //#region Providers
    //#endregion

    //#region event
    //#endregion
  },
};
</script>

<style scoped src="@/assets/css/companyInformation.css" />
