import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

library.add(fas, fab)

createApp(App).use(store).use(router)
.component('fa', FontAwesomeIcon)
.mount('#app')
