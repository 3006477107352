<template>
  <div class="">
    <div class="mt-4">
      <p class="lead">Perdón, no encontramos productos con esas palabras...</p>
      <hr class="my-3" style="width: 60%" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NoDataComp",
  setup() {},
};
</script>