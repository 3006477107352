<template>
  <div class="row">
    <div
      class="card-deck col-12 col-md-4 col-lg-3 mb-3"
      v-for="(item, index) in categories.value" :key="index">
      <CategoryComp :item="item"></CategoryComp>
    </div>
  </div>
</template>

<script>
//#region Imports
import CategoryComp from "@/components/CategoryComp.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref } from 'vue';
//#endregion

export default {
  name: "CategoriesComp",
  components: { CategoryComp },
  setup() {
    //#region Usings
    const store = useStore();
    //#endregion

    //#region Injects
    //#endregion

    //#region Consts
    const categories = ref([]);
    //#endregion
    
    //#region Private Methods
    const loadCategories = () =>{
      categories.value = computed(() => store.getters['productsAndServices/GetCategories'])
    }
    //#endregion
    
    //#region Public Methods
    //#endregion

    //#region Providers
    //#endregion

     //#region events
    onMounted(() => {
      loadCategories();
    });
    //#endregion

    return { categories };
  }
};
</script>

