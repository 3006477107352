<template>
  <!-- Footer container -->
  <footer class="background-custom mt-5">
    <section class="pt-1 pb-5">
      <div class="container text-md-start mt-5 mx-auto">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-xxl-5 col-xl-6 col-lg-7 mb-4 d-flex flex-column align-items-center">
            
            <div>
            <h6 class="fw-bold mt-3">{{ footerModel.Titles[2].Description }}</h6>
            <hr class="mb-4 mt-0 d-inline-block mx-auto"
              style="width: 60px; background-color: #7c4dff; height: 2px"
            />
            </div>
            <table>
       
          <tr v-for="(value, index) in footerModel.Contact" :key="index">
                   
                  <td><fa class="fa-lg me-3" :icon="value.Image"></fa>{{ value.Name }}</td>
                  <td v-if="value.IsCompanyImage"><fa v-if="value.ImagePhone !== null" class="fa-lg me-3" :icon="['fab', value.ImagePhone]"></fa>
                  <a v-bind:href="'https://api.whatsapp.com/send?phone=506' + value.Phone + '&text=Hola, necesito más información!'" class="linkCompanyImg" target="_blank"> {{ value.Phone }}</a>
                  </td>  
                  <td v-if="!value.IsCompanyImage"><fa v-if="value.ImagePhone !== null" class="fa-lg me-3" :icon="value.ImagePhone"></fa>{{ value.Phone }}</td>
                   <td><fa v-if="value.ImagePhone2 !== null" class="fa-lg me-3 ms-3" :icon="value.ImagePhone2"></fa>{{ value.Phone2 }}</td>       
                
            </tr>
           
            </table>
          </div>
         
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-xxl-3 col-xl-2 col-lg-2 mb-4 d-flex flex-column align-items-xl-center align-items-lg-start align-items-center">
            
            <div>
              <h6 class="fw-bold mt-3">{{ footerModel.Titles[1].Description }}</h6>
              <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style="width: 60px; background-color: #7c4dff; height: 2px"
              />
            </div>
            <section class="mt-1">
            
              <a
                v-for="(value, index) in footerModel.SocialMedia"
                :key="index"
                class="btn m-1 rounded-square"
                :class="value.Image"
                :href="value.Url"
                target="_black"
                role="button"
              >
                <fa :icon="['fab', value.Image]"></fa>
              </a>
             
            </section>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          
          <!-- Grid column -->
           <div class="col-xxl-4 col-xl-4 col-lg-3 mb-4 d-flex flex-column align-items-center">
            <!-- Content -->
            <div>
              <h6 class="fw-bold mt-3">{{ footerModel.Titles[0].Description }}</h6>
              <hr
                class="mb-4 mt-0 d-inline-block mx-auto"
                style="width: 60px; background-color: #7c4dff; height: 2px"
              />
            </div>
            <table>
             
              <tr v-for="(value, index) in footerModel.TypePayments" :key="index">
                  <td>
                      <fa
                        v-if="value.IsCompanyImage"
                        class="fa-lg me-3"
                        :icon="['fab', value.Image]"
                      ></fa>
                      <fa v-else :icon="value.Image" class="fa-lg"></fa>
                  </td>
                  <td>
                    {{ value.Name }}
                  </td>
              </tr>
              
            </table>
            
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>

    <section class="d-flex justify-content-between p-4 copyright">
      <div class="me-5">
        <div>
          © 2022 Copyright, Todos los derechos resarvados por
          {{ footerModel.NameCompany }}
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
//#region Imports
import { useStore } from "vuex";
import { onBeforeMount, onMounted, ref } from "vue";
//#endregion

export default {
  name: "FooterComp",
  setup() {
    //#region Usings
     const store = useStore();
    //#endregion

    //#region Injects
    //#endregion

    //#region Consts
    const footerModel = ref({
      Titles : [ {}, {}, {} ],
      SocialMedia : [  ],
      Contact : []
    });
    //#endregi
    //#endregion

    //#region Private Methods
    const loadFooter = () =>{
      store.dispatch("settingsSite/fechFooter")
        .then((response) => {
          footerModel.value = response;
        });
    }
    //#endregion

    //#region Public Methods
    //#endregion

    //#region Providers
    //#endregion

    //#region events
    onBeforeMount(() => {
      loadFooter();
    });
    //#endregion

    return { footerModel };
  },
};
</script>

<style scoped src="@/assets/css/footer.css" />
