import * as general from "@/assets/js/general.js";

export default {
    namespaced: true,
    state: {
        categories: [],
        items: []
    },
    mutations: {
        SetCategories(state, payload) {
            state.categories = payload
        },
        SetItems(state, payload) {
            state.items = payload
        }
    },
    actions: {
        async fechCategories({ commit }) {
            try {
                const res = await fetch(`${process.env.VUE_APP_HADES_API}/category/${process.env.VUE_APP_IDCOMPANY}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type' : 'application/json',
                    }
                })

                const resDb = await res.json();

                if (!resDb.errors){

                    resDb.data.forEach(element => {
                        try {
                           element.Image = general.getImageFrom(`Categories/${element.Image}`);
                        } catch (error) {
                          console.log(error);
                        }
                      });
            
                    commit('SetCategories', resDb.data)

                    return resDb.data;
                }
                else
                {
                    console.log(resDb.message)
                }

            } catch (error) {
                console.log(error)
            }
        },
        async fechItems({ commit }, payload) {
            try {
                const res = await fetch(`${process.env.VUE_APP_HADES_API}/items`, {
                    method: 'POST',
                    headers: {
                        'Content-Type' : 'application/json',
                    },
                    body: JSON.stringify({
                        idItem : payload.idItem,
                        idCategory : payload.idCategory,
                        name : payload.searchText.toLowerCase(),
                        showInBanner : payload.showBanner
                    })
                });

                const resDb = await res.json();
                
                if (!resDb.errors){
                    commit('SetItems', resDb.data)

                    return resDb.data;
                }
                else
                {
                    console.log(resDb.message)
                }

            } catch (error) {
                console.log(error)
            }
        }
    },
    getters: {
        GetCategories(state) {
            return state.categories;
        }
    }
}