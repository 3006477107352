import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Site from '@/views/Site.vue'
import Error404 from '@/views/Error404.vue'
import ProductsAndServices from '@/views/ProductsAndServices.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path : '/',
    name : 'Site',
    component : Site
  },
  {
    path : '/ProductsAndServices',
    name : 'ProductsAndServices',
    component : ProductsAndServices
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: Error404,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
