<template>
  <div id="carousel-home" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button v-for="(item, index) in banners" :key="index" 
                type="button" 
                data-bs-target="#carousel-home" 
                :data-bs-slide-to="index"  
                :class="{'active': item.IsMain === 1 }"></button>
      </div>
      <div class="carousel-inner">
        <div v-for="item in banners" :key="item.IdBanner" 
            :class="{ 'carousel-item': true, 'active': item.IsMain === 1 }" 
            :data-bs-interval="item.Duration">
          <video v-if="item.IsVideo" loop playsinline muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" class="banner d-block w-100">
            <source :src="item.Image" type="video/mp4" />
          </video>
          <img v-else-if="!item.IsVideo" :src="item.Image" class="banner d-block w-100" />
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carousel-home" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carousel-home" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
</template>

<script>
//#region Imports
import * as general from "@/assets/js/general.js";
import { useStore } from "vuex";
import { inject, onMounted, ref } from "vue";
//#endregion

export default {
  name: "BannerComp",
  setup() {
    //#region Usings
     const store = useStore();
    //#endregion

    //#region Injects
    const isLoading = inject('isLoading', ref(false));
    //#endregion

    //#region Consts
     const banners = ref([]);
    //#endregion

    //#region Private Methods
    const loadBanner = () =>{
      isLoading.value = true;
      store.dispatch("settingsSite/fechBanners")
        .then((response) => {
          response.forEach(element => {
            element.Image = general.getImageFrom(`Page/${element.Image}`)
          });
          banners.value = response;
          isLoading.value = false;
        });
    }
    //#endregion
   
    //#region Public Methods
    //#endregion

    //#region Providers
    //#endregion

    //#region events
    onMounted(() => {
      loadBanner();
    });
    //#endregion

    return { banners };
  }
};
</script>

<style scoped src="@/assets/css/banner.css" />
