<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
//#region Imports
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { inject } from "vue";
//#endregion

export default {
  components: { Loading },
  name: "LoaderComp",
  setup() {
    //#region Usings
    //#endregion

    //#region Injects
    const isLoading = inject('isLoading')
    //#endregion

    //#region Consts
    //#endregion

    //#region Private Methods
    //#endregion

    //#region Public Methods
    //#endregion

    //#region Providers
   
    //#endregion

    //#region event
    //#endregion

    return { isLoading };
  },
};
</script>