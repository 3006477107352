<template>
  <div class="row">
    <div class="card-deck col-12 col-md-4 col-lg-3 mb-3"
      v-for="(item, index) of items"
      :key="index">
      <ItemComp :itemModel="item"></ItemComp>
    </div>
     <LoaderComp v-show="isLoading==true"></LoaderComp>
     <NoDataComp v-show="isLoading==false" v-if="count == '0'"></NoDataComp>
  </div>
</template>

<script>
//#region Imports
import ItemComp from "@/components/ItemComp.vue";
import NoDataComp from "@/components/NoDataComp.vue";
import LoaderComp from '@/components/LoaderComp.vue';
import { useStore } from "vuex";
import { ref, inject, watchEffect, provide } from "vue";
import { Modal } from 'bootstrap'
import * as general from "@/assets/js/general.js";
//#endregion

export default {
  name: "ItemsComp",
  components: { ItemComp, NoDataComp, LoaderComp },
  setup() {
    //#region Usings
    const store = useStore();
    //#endregion

    //#region Injects
    const idCategory = inject("idCategory",0);
    const idItem = inject("idItem",0);
    const searchText = inject("searchText","");
    const elementsGalery = inject("elementsGalery",[]);
    //#endregion

    //#region Consts
    const items = ref([]);
    const count = ref(0);
    const isLoading = ref(false);
    //#endregion

    //#region Private Methods
    const getItems = () => {
        isLoading.value = true;
        store.dispatch("productsAndServices/fechItems", {
        idCategory : idCategory.value,
        idItem : idItem.value,
        showBanner : false,
        searchText: searchText.value
      }).then((response) => {
         response.forEach(element => {
           element.Image = general.getImageFrom(`Items/${element.Image}`)
          });
        items.value = response;
        count.value = items.value.length;
        isLoading.value = false;
      });
    }

    const showDetail = () =>
    {
      var modal = new Modal( document.getElementById('galeryModal') , {})
      elementsGalery.value = items.value;
      modal.show()
    }
    //#endregion

    //#region Public Methods
    //#endregion

    //#region Providers
    provide("showDetail", showDetail);
    provide("isLoading", isLoading);
    //#endregion

    //#region event
    watchEffect(() => {
      getItems();
    });
    //#endregion

    return { items, count, isLoading };
  },
};
</script>
