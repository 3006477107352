<template>
<div class="container">
    <div class="mb-3 d-flex justify-content-center">
        <button class='btn btn btn-general fade-zoom me-2' data-bs-target="#carouselExampleControls" data-bs-slide="prev">Anterior</button>
        <button class='btn btn btn-general fade-zoom' data-bs-target="#carouselExampleControls" data-bs-slide="next">Siguiente</button>
    </div>
</div>
<div class="col-12">
    <div id="carouselExampleControls" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
        <div class="carousel-inner">
            <div v-for="(item, index) in elements" :key="item.IdItem" 
             :class="{ 'carousel-item': true, 'active': item.IdItem === select }" >
                <div class="card" style="max-width: 800px;">
                    <div class="row g-0">
                        <div class="col-lg-5">
                        <img :src="item.Image" class="d-block w-100 rounded-start" :alt="item.imgAlt">
                        </div>
                        <div class="col-lg-7">
                            <div class="card-body mx-3">
                                <div class="row mt-2">
                                    <div class="col-lg-4 fw-bold">Codigo:</div>
                                    <div class="col-lg-8">{{item.IdItem}}</div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-4 fw-bold">Nombre:</div>
                                    <div class="col-lg-8">{{item.Name}}</div>
                                </div>
                                <div class="row mt-2" v-show="!!item.Description">
                                    <div class="col-lg-4 fw-bold">Descripción:</div>
                                    <div class="col-lg-8">{{item.Description}}</div>
                                </div>
                                <div class="row mt-2" v-show="item.Price != null">
                                    <div class="col-lg-4 fw-bold">Precio:</div>
                                    <div class="col-lg-8">{{item.Price}}</div>
                                </div>
                                <div class="row mt-2" v-show="item.Amount != null">
                                    <div class="col-lg-4 fw-bold">Cantidad:</div>
                                    <div class="col-lg-8">{{item.Amount}}</div>
                                </div>                    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid d-flex flex-row-reverse mt-3 mb-2 fw-bold">
                    Producto: {{index+1}} de {{elements.length}}.
                </div>
            </div>

        </div>
    </div>
</div>

</template>

<script>
//#region Imports
import { inject, ref, watchEffect } from 'vue';
//#endregion

export default {
     name: "ItemDetailComp",
     setup() {
    //#region Usings
    //#endregion

    //#region Injects
    const elementsGalery = inject("elementsGalery", []);
    const itemSelected = inject('itemSelected',{})
    //#endregion

    //#region Consts
    const elements = ref([]);
    const select = ref({});
    //#endregion

    //#region Private Methods
    const loadGalery = () =>
    {
        select.value = itemSelected.idItem;
        elements.value = elementsGalery.value;
    }
    //#endregion

    //#region Public Methods
    //#endregion

    //#region Providers
    //#endregion

    //#region event
    watchEffect(() => {
        loadGalery();
    })
    //#endregion

    return { elements, select }

    }
}
</script>

<style scoped src="@/assets/css/itemDetail.css" />