<template>
  <div class="container">
    <SearchBarComp @keyup.enter="findItems"></SearchBarComp>
    <hr />

    <div class="jumbotron container-fluid">
      <div class="row">
        <div class="col">
          <h1 class="display-4">Categorias</h1>
        </div>
        <div class="col d-flex flex-column align-items-end justify-content-center">
          <a class="btn btn-general fade-zoom" @click="goToSite" role="button">Ver Mas...</a>
        </div>
      </div>
      <hr class="my-4" />
      <CategoriesComp @click="goToItem"></CategoriesComp>
      <hr class="my-4" />
    </div>
  </div>
</template>

<script>
//#region Imports
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CategoriesComp from "@/components/CategoriesComp.vue";
import { ref, provide, onMounted } from "vue";
import SearchBarComp from "@/components/SearchBarComp.vue";
//#endregion

export default {
  name: "ProductsAndServicesComp",
  components: { CategoriesComp, SearchBarComp },
  setup() {
    //#region Usings
    const store = useStore();
    const router = useRouter();
    //#endregion

    //#region Injects
    //#endregion

    //#region Consts
    const idCategory = ref(0);
    const searchText = ref("");
    //#endregion

    //#region Private Methods
    const loadCategories = () => {
      store.dispatch("productsAndServices/fechCategories");
    };
    //#endregion

    //#region Public Methods
    const goToItem = () => {
      router.push({
        name: "ProductsAndServices",
        params: { idCategory: idCategory.value },
      });
    };

    const findItems = () => {
      router.push({
        name: "ProductsAndServices",
        params: { searchText: searchText.value },
      });
    };

    const goToSite = () => {
      router.push({
        name: "ProductsAndServices",
      });
    };
    //#endregion

    //#region Providers
    provide("idCategory", idCategory);
    provide("searchText", searchText);
    //#endregion

    //#region event
    onMounted(() => {
      loadCategories();
    });
    //#endregion

    return { findItems, goToItem, goToSite };
  },
};
</script>
