
// *********** Public Fuctions ***************//

// funtion to get section
export const getSection = () => {
  return `#${document.querySelector('.fade-in').id}`;
}

// function to change scroll top
export const changeScrollTop = (href) => {
    const el = href ? document.querySelector(href) : null
    if (el) {
      window.scrollTo(0, el.offsetTop -190);
      return true;
    }
    return false;
}

//funtion on scroll animate section
export const handleScroll = (event) => {
  const fadeInElements = Array.from(document.getElementsByClassName('fade-in'))
  fadeInElements.forEach((elem) => {
    elem.style.opacity = '0'
    elem.style.transform = 'scale(0.8)'

    if (isElemVisible(elem)) {
      elem.style.opacity = '1'
      elem.style.transform = 'scale(1)'
    }
  });      
}

export const getImageFrom = (path) => {
  try {
    return require(`@/Images/${path}`);
 } catch (error) {
    console.log(error);
 }
}

// *********** Private Fuctions ***************//

//function validate if section is visible to render
const isElemVisible = (el) => {
  var rect = el.getBoundingClientRect()
  var elemTop = rect.top + 200
  var elemBottom = rect.bottom
  return elemTop < window.innerHeight && elemBottom >= 0
}
