<template>
  <div class="mt-4">
    <div id="Home" class="fade-in p-md-4 pt-4 mt-md-5 bg-general">
      <HomeComp></HomeComp>
    </div>
    <div id="Us" class="fade-in p-md-4 pt-4 mt-5 bg-general">
      <UsComp></UsComp>
    </div>
    <div id="ProdAndServ" class="fade-in p-md-4 pt-4 mt-5 bg-general">
      <ProductsAndServicesComp></ProductsAndServicesComp>
    </div>
    <div id="Contact" class="fade-in p-md-4 pt-4 mt-5 bg-general">
      <ContactComp></ContactComp>
    </div>
  </div>
</template>

<script>
//#region Imports
import * as general from "@/assets/js/general.js";
import HomeComp from "@/components/HomeComp.vue";
import UsComp from "@/components/UsComp.vue";
import ProductsAndServicesComp from "@/components/ProductsAndServicesComp.vue";
import ContactComp from "@/components/ContactComp.vue";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
//#endregion


export default {
  components: { HomeComp, UsComp, ProductsAndServicesComp, ContactComp },
  setup() {
    //#region Usings
    const route = useRoute();
    //#endregion

    //#region Injects
    //#endregion

    //#region Consts
    //#endregion

    //#region Private Methods
    //#endregion

    //#region Public Methods
    //#endregion

    //#region Providers
    //#endregion

    //#region event
    onMounted(() => {
      document.addEventListener("scroll", general.handleScroll);
      $(document).ready(function(){
        general.handleScroll();
        general.changeScrollTop(route.params.section);
       });
    });
    //#endregion
  },
};
</script>
