<script>
export default class FooterModel {
  NameCompany;
  Titles = [];
  TypePayments = [];
  SocialMedia = [];
  Contact = [];
  constructor() {
  /*  this.NameCompany = "Ticaflor S.A.";

    this.Titles = [
      { Description: "Medios de Pago" },
      { Description: "Siguenos en" },
      { Description: "Contacto" },
    ];
    this.TypePayments = [
      { Name: "Visa", IsCompanyImage: true, Image: "cc-visa" },
      { Name: "Master Card", IsCompanyImage: true, Image: "cc-mastercard" },
      { Name: "Sinpe Movil", IsCompanyImage: false, Image: "mobile" },
      { Name: "Transferencia Bancaria", IsCompanyImage: false, Image: "exchange-alt" }
    ];
    this.SocialMedia = [
      { Image: "facebook", Url: "https://www.facebook.com/ticaflordistribuidora/" },
      { Image: "instagram", Url: "https://www.instagram.com/ticaflorcr/" },
    ];
    this.Contact = [
      {
        Name: "ventas@ticaflor.com",
        Image: "envelope",
        Phone: "",
        ImagePhone: "",
        Phone2: "",
        ImagePhone2: "",
      },
      {
        Name: "Cartago",
        Image: "store-alt",
        Phone: "12345678",
        ImagePhone: "phone",
        Phone2: "12345678",
        ImagePhone2: "whatsapp",
      },
      {
        Name: "Perez Zeledon",
        Image: "store-alt",
        Phone: "12345678",
        ImagePhone: "phone",
        Phone2: "12345678",
        ImagePhone2: "whatsapp",
      },
      {
        Name: "Liberia",
        Image: "store-alt",
        Phone: "12345678",
        ImagePhone: "phone",
        Phone2: "12345678",
        ImagePhone2: "whatsapp",
      },
      {
        Name: "Pinares",
        Image: "store-alt",
        Phone: "000000",
        ImagePhone: "phone",
        Phone2: "000000",
        ImagePhone2: "whatsapp",
      },
    ];*/
  }
}
</script>
