<template>
  <!-- Header container -->
  <header class="sticky-top background-custom">
    <!-- Logos in center -->
    <div class="container-lg d-flex justify-content-between px-xl-5">
      <a class="navbar-brand ps-xl-4" href="#"
        ><img src="@/Images/Page/logo1.png" class="img-fluid" alt="Tica Flor"
      /></a>
      <a class="navbar-brand ps-xl-4" href="#"
        ><img src="@/Images/Page/logo2.png" class="img-fluid" alt="Tica Flor"
      /></a>
    </div>
    <!-- Logos in center -->

    <!-- Navbar -->
    <nav
      id="nav-principal"
      class="navbar navbar-expand-lg navbar-light pb-lg-0 pb-sm-3"
    >
      <!-- Grid container -->
      <div class="container-fluid">
        <!-- Button toggle for small device -->
        <button
          class="navbar-toggler mt-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#nav-toggle-principal"
          aria-controls="nav-toggle-principal"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Button toggle for small device -->

        <!-- Routes -->
        <div
          class="collapse navbar-collapse justify-content-center mt-3 mt-lg-0"
          id="nav-toggle-principal"
        >
          <ul class="navbar-nav nav nav-pills text-center">
            <li class="nav-item">
              <a
                class="nav-link py-lg-2 px-lg-5 active"
                href="#Home"
                name="Home"
                @click.prevent="scrollIntoView"
                >Inicio</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-lg-2 px-lg-5"
                href="#Us"
                name="Us"
                @click.prevent="scrollIntoView"
                >Nosotros</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-lg-2 px-lg-5"
                href="#ProdAndServ"
                name="ProdAndServ"
                @click.prevent="scrollIntoView"
                >Productos y Servicios</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-lg-2 px-lg-5"
                href="#Contact"
                name="Contact"
                @click.prevent="scrollIntoView"
                >Contacto</a
              >
            </li>
          </ul>
        </div>
        <!-- Routes -->
      </div>
      <!-- Grid container -->
    </nav>
    <!-- Navbar -->
  </header>
  <!-- Header container -->
</template>

<script>
//#region Imports
import * as general from "@/assets/js/general.js";
import { useRouter } from "vue-router";
//#endregion

export default {
  name: "HeaderComp",
  setup() {
    //#region Usings
    const router = useRouter();
    //#endregion

    //#region Injects
    //#endregion

    //#region Consts
    //#endregion

    //#region Private Methods
    //#endregion

    //#region Public Methods
    const scrollIntoView = (event) => {
      const href = event.target.getAttribute("href");
      general.changeScrollTop(href);
      router.push({ name: "Site", params: { section: href } });
    };
    //#endregion

    //#region Providers
    //#endregion

    //#region events
    //#endregion

    return { scrollIntoView };
  },
};
</script>

<style scoped src="@/assets/css/header.css"/>