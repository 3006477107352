import footerModel from "@/models/custom/footerModel.vue";
import contactModel from "@/models/custom/contactModel.vue";
import * as general from "@/assets/js/general.js";

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async fechBanners() {
            try {
                 const res = await fetch(`${process.env.VUE_APP_HADES_API}/banner/${process.env.VUE_APP_IDCOMPANY}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type' : 'application/json',
                    }
                })

                const resDb = await res.json();

                if (!resDb.errors){
                    return resDb.data;
                }
                else
                {
                    console.log(resDb.message)
                }

            } catch (error) {
                console.log(error)
            }
        },
        async fechUsInformation() {
            try {
                const res = await fetch(`${process.env.VUE_APP_HADES_API}/companyInfo/${process.env.VUE_APP_IDCOMPANY}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type' : 'application/json',
                    }
                })

                const resDb = await res.json();

                if (!resDb.errors){
                    const res = {};
                    const company = resDb.data[0];
                    res.Mission = {
                        title: company.NameMission,
                        description: company.DescriptionMission,
                        image: general.getImageFrom(`Page/${company.ImageMission}`)
                    };
                    res.Vision = {
                        title: company.NameVision,
                        description: company.DescriptionVision,
                        image: general.getImageFrom(`Page/${company.ImageVision}`)
                    };
                    res.Values = {
                        title: company.NameValues,
                        description: company.DescriptionValues,
                        image: general.getImageFrom(`Page/${company.ImageValues}`)
                    };
                    res.Us = {
                        title: company.NameUs,
                        description: company.DescriptionUs,
                        image: general.getImageFrom(`Page/${company.ImageUs}`)
                    };
    
                    return res;
                }
                else
                {
                    console.log(resDb.message)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async fechFooter() {
            try {
                const res = await fetch(`${process.env.VUE_APP_HADES_API}/footer`, {
                     method: 'POST',
                     headers: {
                         'Content-Type' : 'application/json',
                     },
                     body: JSON.stringify({
                         idCompany : process.env.VUE_APP_IDCOMPANY
                     })
                 });
 
                 let response = await res.json();
                 let footer = new footerModel()
                 footer = response;
                 return footer;
 
             } catch (error) {
                 console.log(error)
             } 
        },
        async fechContact() {
            try {
               const res = await fetch(`${process.env.VUE_APP_HADES_API}/contact`, {
                    method: 'POST',
                    headers: {
                        'Content-Type' : 'application/json',
                    },
                    body: JSON.stringify({
                        idCompany : process.env.VUE_APP_IDCOMPANY
                    })
                });

                let response = await res.json();
                let contact = new contactModel()
                contact.Titles = response.Titles;
                contact.Child = response.Child;
                return contact;
                

            } catch (error) {
                console.log(error)
            } 
        },
    },
    getters: {}
}