<template>
      <input
          type="text"
          placeholder="Estoy Buscando..."
          class="form-control mt-3 shadow mb-3 border border-success border-2"
          v-model="searchText"
        />
</template>

<script>
//#region Imports
import { inject } from "vue";
//#endregion

export default {
 name: "SearchBarComp",
  components: {  },
  setup() {
    //#region Usings
    //#endregion

    //#region Injects
    const searchText = inject("searchText", "");
    //#endregion

    //#region Consts
    //#endregion

    //#region Private Methods
    //#endregion

    //#region Public Methods
    //#endregion

    //#region Providers
    //#endregion

    //#region event
    //#endregion
    
    return { searchText };
  },
};
</script>

