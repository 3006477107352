<template>
  <div>
    <HeaderComp></HeaderComp>
    <div class="container-md px-lg-5">
      <router-view></router-view>
    </div>
    <FooterComp></FooterComp>
    <LoaderComp></LoaderComp>
  </div>
</template>

<script>
import FooterComp from "@/components/FooterComp.vue";
import HeaderComp from "@/components/HeaderComp.vue";
import LoaderComp from './components/LoaderComp.vue';
import { provide, ref } from 'vue';

export default {
  components: { HeaderComp, FooterComp, LoaderComp },
  setup() {
    provide("isLoading", ref(false));
  },
};
</script>

<style>
@import "./assets/css/general.css";
</style>